import React from 'react';

const Card = ({ className, children, height }) => (
  <div
    className={`p-12 rounded-lg card-shadow border border-solid border-gray-200 ${className}`}
    style={{
      minHeight: height+'px',
    }}
  >
    {children}
  </div>
);

export default Card;
