/* This example requires Tailwind CSS v2.0+ */
import { Disclosure } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/outline'
import React from 'react';
const faqs = [
  // {
  //   question: "Q: How much does it cost to hire teams & engineers",
  //   answer:
  //     "It depends on multiple factors such as project requirements, contract time, size of the team, technology expertise needed etc. C4Scale's pricing model works well for all tiers from start ups, Enterprises, Medium-sized companies",
  // },
  {
    question: "Q: How does the contract work?",
    answer:
      "Contracting terms depends on the scope of the project/product build. We can discuss, sign MSA, SoW & NDA. You own IP & Copyrights. All work we do for you is strictly confident.",
  },{
    question: "Q: How do you hire engineers",
    answer:
      "Hiring vetted developers who truly deliver is tough. It takes years of experiencee hiring top notch talent. We hire people, through video sessions and give them a platform to showcase their communication skills, problem solving ability.",
  },
  {
    question: "Q: Who will be the Person In Contact at C4Scale?",
    answer:
      "Each engagement is assigned with an engineering manager, tech lead or Account Lead. They will be the PIC for all project related",
  },
  //  {
  //   question: "Q: What if one of the team member does not perform well?",
  //   answer:
  //     "We can replace the engineer. Alternatively, we can assess the need to do it and work the best for you",
  // },
  //  {
  //   question: "Q: Can I cancel the contract or the relationship?",
  //   answer:
  //     "Yes, you can. Please let us know about it atleast a month in advance",
  // },
  {
    question: "Q: What is the transparency level of work",
    answer:
      "We are 100% transparent on what we do and how we do. We use appropriate tooling depending on the projects. These tools will have full visibility of the work assigned, progress etc.,",
  },
  // More questions...
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Example() {
  return (
    <div className='bg-gray-50'>
      <div className="max-w-7xl mx-auto py-12 px-4 sm:py-16 sm:px-6 lg:px-8">
        <div className="max-w-3xl mx-auto divide-y-2 divide-gray-200">
          <h2 className="text-center text-3xl font-extrabold text-gray-900 sm:text-4xl">FAQ</h2>
          <dl className="mt-6 space-y-6 divide-y divide-gray-200">
            {faqs.map((faq) => (
              <Disclosure as="div" key={faq.question} className="pt-6">
                {({ open }) => (
                  <>
                    <dt className="text-lg">
                      <Disclosure.Button className="text-left w-full flex justify-between items-start text-gray-400">
                        <span className="font-medium text-gray-900">{faq.question}</span>
                        <span className="ml-6 h-7 flex items-center">
                          <ChevronDownIcon
                            className={classNames(open ? '-rotate-180' : 'rotate-0', 'h-6 w-6 transform')}
                            aria-hidden="true"
                          />
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel as="dd" className="mt-2 pr-12" style={{background: open? 'aliceblue': 'none', padding: open? '10px' : 0}}>
                      <p className="text-base text-gray-600">{faq.answer}</p>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            ))}
          </dl>
        </div>
      </div>
    </div>
  )
}
